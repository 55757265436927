<template lang="pug">
  .userProfile
    h1.title Perfil
    .item
      span.item__title Email:

      span.item__description {{takeUser.email}}

    .item
      span.item__title Nome:

      span.item__description {{takeUser.name}}

    .item
      span.item__title Id:

      span.item__description {{takeUser.id}}

    h2.subtitle(
      v-if="takePermissions.length"
      ) Minhas permissões

    .item(
      v-if="takePermissions.length"
      v-for="item in takePermissions"
    )
      span.item__description {{item}}

    h2(
      v-if="takePermissions.includes('Admin')"
    ).subtitle Ações Admin

    Button.actions(
      v-if="takePermissions.includes('Admin')"
      text="Conceder Permissão"
      @click="doShowModalPermission"
    )

    Button.actions(
      v-if="takePermissions.includes('Admin')"
      text="Gerar Chaves"
      @click="doShowModalGenerateKeys"
    )

    Button.actions(
      v-if="takePermissions.includes('Admin')"
      text="Adicionar Equipe"
      @click="doShowModalAddTeam"
    )

    Button.actions(
      v-if="takePermissions.includes('Admin')"
      text="Adicionar Robô"
      @click="doShowModalAddRobot"
    )

    Modal(
      :visible="showModal"
      @close="doSetModalVisibility"
    )
      .modal-content(
        v-if="step === 0"
      )
        span.modal-content.modal-content__title Id do usuário
        input.modal-content.modal-content__input(
          type="text"
          placeholder="Digite aqui o Id do usuário"
          v-model="userId"
        )
        Button(
          text="Próximo"
          border="base"
          @click="doPassStep"
        )

      .modal-content(
        v-if="step === 1"
      )
        span.modal-content.modal-content__title Função

        .modal-content.modal-content__functions
          Check(
            v-for="(option, index) in takeRoles"
            :key="index"
            :value="{text: option.name, id: option.id}"
            @change="doSetRoles"
          )

        Button(
          text="Confirmar"
          border="base"
          @click="doGrantPermission"
        )

      .modal-content(
        v-if="step === 2"
      )
        span.modal-content.modal-content__title Selecione a categoria para as novas chaves

        Select.modal-content.modal-content__select--big(
          defaultText="Selecionar Categoria"
          :options="takeCategories"
          type='drop'
          @emit="doSetSelectedCategory"
        )

        Button(
          text="Confirmar"
          border="base"
          @click="doGenerateKeys"
        )

      .modal-content(
        v-if="step === 3"
      )
        span.modal-content.modal-content__title Nova Senha?

        input.modal-content.modal-content__input(
          type="text"
          placeholder="Digite aqui a nova senha"
        )

        span.modal-content.modal-content__title Confirme nova senha?

        input.modal-content.modal-content__input(
          type="text"
          placeholder="Digite aqui a senha, novamente"
        )

        Button(
          text="Confirmar"
          border="base"
          @click="doSetModalVisibility('true')"
        )

      .modal-content(
        v-if="step === 4"
      )
        span.modal-content.modal-content__title Nome da Equipe

        input.modal-content.modal-content__input(
          type="text"
          v-model="teamName"
          placeholder="Digite aqui o nome da equipe"
        )

        Button(
          text="Confirmar"
          border="base"
          @click="doCreateTeam"
        )

      .modal-content(
        v-if="step === 5"
      )
        span.modal-content.modal-content__title Nome Robô

        input.modal-content.modal-content__input(
          type="text"
          placeholder="Digite aqui o nome do robô"
          v-model="robotName"
        )

        Select.modal-content.modal-content__select(
          defaultText="Selecionar Categoria"
          :options="takeCategories"
          type='drop'
          @emit="doSetSelectedCategory"
        )

        Select.modal-content.modal-content__select(
          defaultText="Selecionar Equipe"
          :options="takeTeams"
          type='drop'
          @emit="doSetSelectedTeam"
        )

        Button(
          text="Confirmar"
          border="base"
          @click="doCreateRobot"
        )
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex'

import {
  Button,
  Select,
  Check
} from '@/components/atoms'

import {
  Modal
} from '@/components/molecules'

export default {
  name: 'UserProfile',
  data: () => ({
    robotName: '',
    userId: '',
    userRoles: [],
    showModal: false,
    selectedTeam: {},
    selectedCategory: {},
    teamName: '',
    step: 0
  }),
  created () {
    this.setUser({ user_id: this.getUser.id }).then(response => {
      if (this.getUser && !this.getUser.id) {
        this.$router.push('SignIn')
      }
      this.setRoles()
      this.setTeams()
      this.setCategories()
    })
  },
  components: {
    Button,
    Select,
    Check,
    Modal
  },
  computed: {
    ...mapGetters('teams', [
      'getTeams'
    ]),
    ...mapGetters('categories', [
      'getCategories'
    ]),
    ...mapGetters('roles', [
      'getEventRoles'
    ]),
    ...mapGetters('users', [
      'getUser'
    ]),
    takeUser () {
      return this.getUser
    },
    takeTeams () {
      return [...this.getTeams].map(team => { return { text: team.name, value: team.id } })
    },
    takeCategories () {
      return [...this.getCategories].map(category => { return { text: category.name, value: category.id } })
    },
    takeRoles () {
      return this.getEventRoles
    },
    takePermissions () {
      if (this.getUser && this.getUser.roles && this.getUser.roles.length) {
        return this.getUser.roles.map(role => {
          switch (role) {
          case 1:
            return 'Admin'
          case 2:
            return 'Inspetor de Segurança'
          case 3:
            return 'Juiz de Combate'
          case 4:
            return 'Juiz de Round'
          case 5:
            return 'Pit Runner'
          default:
            return ''
          }
        })
      }
      return []
    }
  },
  methods: {
    ...mapActions('robots', [
      'setRobots',
      'setCreateRobot'
    ]),
    ...mapActions('teams', [
      'setTeams',
      'setCreateTeam'
    ]),
    ...mapActions('categories', [
      'setCategories'
    ]),
    ...mapActions('roles', [
      'setRoles'
    ]),
    ...mapActions('users', [
      'setUser',
      'setAddEventRole'
    ]),
    ...mapActions('fights', [
      'setGenerateKeys'
    ]),
    doShowModalPermission () {
      this.step = 0
      this.showModal = true
    },
    doShowModalGenerateKeys () {
      this.step = 2
      this.showModal = true
    },
    doShowModalPassword () {
      this.step = 3
      this.showModal = true
    },
    doShowModalAddTeam () {
      this.step = 4
      this.showModal = true
    },
    doShowModalAddRobot () {
      this.step = 5
      this.showModal = true
    },
    doSetModalVisibility (e) {
      this.showModal = e
    },
    doSetSelectedCategory (value) {
      this.selectedCategory = { ...value }
    },
    doSetSelectedTeam (value) {
      this.selectedTeam = { ...value }
    },
    doPassStep () {
      this.step = this.step + 1
    },
    doCreateRobot (e) {
      const robot = { name: this.robotName, class: this.selectedCategory.value, team: this.selectedTeam.value }
      this.setCreateRobot(robot).then(
        this.showModal = false
      )
    },
    doGrantPermission () {
      const updateUserRoles = { user_id: this.userId, role_ids: this.userRoles, admin_id: this.getUser.id, event_id: 1 }
      this.setAddEventRole(updateUserRoles)
      this.showModal = false
      this.setUser({ user_id: this.getUser.id })
    },
    doSetRoles (e) {
      if (e.checked) {
        this.userRoles = [...this.userRoles, e.value.id]
      } else {
        this.userRoles = this.userRoles.filter(role => role !== e.value.id)
      }
    },
    doCreateTeam () {
      this.setCreateTeam({ name: this.teamName, user_id: 2580 })
      this.showModal = false
    },
    doGenerateKeys () {
      const categoryId = this.selectedCategory.value
      let arenaId = 2
      if (categoryId === 3) {
        arenaId = 1
      }
      this.setGenerateKeys({
        event_id: 1,
        category_id: categoryId,
        arena_id: arenaId,
        admin_id: this.getUser.id
      }).then(response => { if (response) { this.doSetModalVisibility(false) } })
    }
  },
  filters: {},
  watch: {}
}
</script>

<style lang="sass" scoped>
  .userProfile
    margin: 0 10vw
    letter-spacing: 0em
    font-style: normal
    display: flex
    flex-direction: column
    align-items: flex-start
    .title
      font-size: 48px
      font-weight: 700
      line-height: 72px
      margin: 32px 0 8px

    .subtitle
      font-size: 32px
      font-weight: 700
      line-height: 48px
      margin: 0 0 8px

    .item
      margin: 8px 0
      &__title
        margin-right: 16px

      &__description
        font-weight: 300

    .actions
      margin: 8px 0

    .modal-content
      max-height: 50vh
      overflow-y: scroll
      overflow-x: clip
      display: flex
      flex-direction: column
      align-items: center
      margin: 40px
      width: 100%
      justify-content: space-around
      &__title
        margin: 0
        font-size: 20px
        font-style: normal
        line-height: 30px
        align-items: flex-start

      &__input
        min-height: 32px
        margin: 8px
        border: none
        border-bottom: 1px solid #000000
        border-color: #000000

      &__select
        max-height: 30%
        margin: 16px 0 0
        align-items: stretch
        &--big
          max-height: 60%

      &__functions
          align-items: flex-start
          justify-content: flex-start
          margin: 16px 0

</style>
